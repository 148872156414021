<template>
<v-app>
    <div class="card card-custom">
        <div class="card-body p-0">
            <div
                    class="wizard wizard-2"
                    id="kt_wizard_v2"
                    data-wizard-state="step-first"
                    data-wizard-clickable="true"
            >
                <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">

                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12">
                            <h4>Manage Fees</h4>
                        </div>
                        <div class="col-12">
                          <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

                          <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                          </v-skeleton-loader>
                          <div class="table-responsive">
                            <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table">
                              <thead>
                              <tr class="px-3">
                                <th class="px-3 wrap-column">Student info</th>
                                <th class="px-3 wrap-column">Info</th>
                                <th class="px-3 wrap-column">Amount</th>
                                <th class="px-3 wrap-column">Invoice date</th>
                                <th class="px-3 wrap-column">Due date</th>
                                <th class="px-3 wrap-column">Cleared</th>
                                <th class="px-3 wrap-column">Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-show="fees.length > 0" v-for="(fee, index) of fees" :key="index">
                                <td class="px-3 wrap-column">
                                  <a class="cursor-pointer" @click="goToStudent(fee.student_id)">{{fee.student_full_name}}</a>
                                  <div>
                                    {{fee.current_grade_title}}/{{fee.current_grade_title}}
                                  </div>
                                  <div>{{fee.academic_year}}</div>

                                </td>
                                <td class="px-3 wrap-column">
                                  {{ fee.title }}
                                  <i v-if="!fee.is_cleared"  class="fas fa-circle"
                                     :class="fee.is_current_installment ? 'dot-active' : 'dot-warning'"></i>
                                </td>

                                <td class="px-3 wrap-column">
                                  <div>
                                    <strong>Amount:</strong> Rs. {{ fee.amount }}
                                  </div>
                                  <div class="mt-3" v-if="fee.discount_amount">
                                    <strong>Discount amount: </strong>
                                    <span>
                                      Rs. {{ fee.discount_amount }}<br>
                                    </span>
                                  </div>
                                </td>

                                <td class="px-3 wrap-column">{{ fee.formatted_invoice_date }}</td>

                                <td class="px-3 wrap-column">{{ fee.due_date_text }}</td>

                                <td class="px-3 wrap-column">
                                  <i class="fas fa-check" v-if="fee.is_cleared" style="color: #00b300"> </i>
                                  <i class="fas fa-ban" style="color: red" v-else></i>
                                </td>

                                <td>
                                  <b-dropdown size="sm" variant="link"
                                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                                              right no-flip>
                                    <template v-slot:button-content>
                                      <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                                      </slot>
                                    </template>
                                    <!--begin::Navigation-->
                                    <div class="navi navi-hover">
                                      <b-dropdown-text tag="div" class="navi-item" v-if="!fee.is_cleared && !fee.invoice_id">
                                        <a class="navi-link" @click="createInvoice(fee.student_id, fee.id)">
                                          <span class="navi-text">Create an invoice</span>
                                        </a>
                                      </b-dropdown-text>

                                      <b-dropdown-text tag="div" class="navi-item" v-if="fee.invoice_id">
                                        <a class="navi-link" @click="sendInvoice(fee.student_id, fee.id)">
                                          <span class="navi-text">{{fee.is_send_invoice ? 'Send again invoice' : 'Send an invoice'}}</span>
                                        </a>
                                      </b-dropdown-text>

                                      <b-dropdown-text tag="div" class="navi-item" v-if="fee.invoice_id">
                                        <a class="navi-link" @click="previewInvoice(fee.invoice_url)">
                                          <span class="navi-text">View an invoice</span>
                                        </a>
                                      </b-dropdown-text>

                                      <b-dropdown-text tag="div" class="navi-item" v-if="!fee.is_cleared && fee.invoice_id">
                                        <a class="navi-link" @click="makeFullPayment(fee)">
                                          <span class="navi-text">Make full payment</span>
                                        </a>
                                      </b-dropdown-text>

                                      <b-dropdown-text tag="div" class="navi-item" v-if="!fee.is_cleared && fee.invoice_id">
                                        <a class="navi-link" @click="makePartialPayment(fee)">
                                          <span class="navi-text">Make partial payment</span>
                                        </a>
                                      </b-dropdown-text>

                                      <b-dropdown-text tag="div" class="navi-item" v-if="!fee.is_cleared && !fee.invoice_id">
                                        <a class="navi-link" @click="openInstallmentForm(fee)">
                                          <span class="navi-text">Create sub installment</span>
                                        </a>
                                      </b-dropdown-text>

                                      <b-dropdown-text tag="div" class="navi-item" v-if="!fee.is_cleared && !fee.invoice_id">
                                        <a class="navi-link" v-if="!fee.discount_amount" @click="applyDiscount(fee.id)">
                                          <span class="navi-text">Apply Discount</span>
                                        </a>
                                        <a class="navi-link" v-else @click="cancelDiscount(fee.id)">
                                          <span class="navi-text">Cancel discount</span>
                                        </a>
                                      </b-dropdown-text>

                                    </div>
                                    <!--end::Navigation-->
                                  </b-dropdown>

                                </td>
                              </tr>
                              <tr v-if="fees.length == 0">
                                <td colspan="7" class="text-center"><strong>Data not available</strong></td>
                              </tr>
                              </tbody>
                            </table>
                          </div>

                        </div>
                        <div class="row" v-if="fees.length>0">
                            <div class="col-12 text-center">
                                <b-pagination
                                        @input="getFees"
                                        v-model="page"
                                        :total-rows="total"
                                        :per-page="perPage"
                                        first-number
                                        last-number
                                ></b-pagination>
                                <!--  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <installment v-if="sub_fee" :user_id="sub_fee.student_id" :dialog="dialog" :fee="sub_fee"
                   @refresh="closeDialog"></installment>
    </div>
</v-app>
</template>
<script>
import event from "@/core/plugins/event";
import Installment from "@/view/pages/student/profile/fee/Installment.vue";
import StudentFeeService from "@/core/services/user/student/fee/StudentFeeService";
import FinancialHistoryService from "@/core/services/finance/history/FinancialHistoryService";

const feeService = new StudentFeeService();
const historyService = new FinancialHistoryService();

export default {
    name: "Fees",
    components: {
      Installment
    },
    data() {
        return {
          fees: [],
          items_in_finance: [],
          fee_id: null,
          sub_fee: null,
          loading: false,
          dialog: false,
          page: null,
          total: null,
          perPage: null
        };
    },
    mounted() {
        this.getFees();
    },
    methods: {

      goToStudent(studentId) {
        this.$tabs.open({  name: "students-summary",
          params: { id: studentId, type: 'student' }})
      },
      getFees() {
        this.loading = true;
          feeService.paginate().then(response => {
            this.loading = false;
            this.fees = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          }).catch(() => {
            this.loading = false;
          });
      },

      createInvoice(studentId, id) {
        this.$confirm({
          message: `Are you sure you want to create invoice this item ?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              feeService.createInvoice(studentId, id).then(response => {
                if(response.data.status == "OK") {
                  this.$snotify.success("Invoice create successfully");
                  this.getFees();
                }
              }).catch(() => {})
            }
          }
        });
      },

      sendInvoice(studentId, id) {
        this.$confirm({
          message: `Are you sure you want to send invoice this item ?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              feeService.sendInvoice(studentId, id).then(response => {
                if(response.data.status == "OK") {
                  this.$snotify.success("Invoice has been sent successfully");
                  this.getFees();
                }
              }).catch(() => {})
            }
          }
        });
      },

      previewInvoice(url) {
        window.open(url, '_blank');
      },

      makeFullPayment(fee) {
        this.$confirm({
          message: `Are you sure you want to make full payment this item ?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              this.isBusy = true
              let data = {
                'payment_status': 'pending',
                'type': 'income',
                'title': 'Academic Fee',
                'student_fee_id': fee.id,
                'invoice_id': fee.invoice_id,
                'payer_user_id': fee.student_id,
                'total_amount': fee.remaining_amount,
                'is_full_paid': 1,
              };
              historyService.createQuote(fee.student_id, data).then((response) => {
                if(response.data.status == "OK"){
                  let history = response.data.history;
                  this.updatePaymentProcessed(fee, history);
                }
              }).catch(() => {
                this.$snotify.error("Can't create payment")
              })
            }
          }
        });
      },

      makePartialPayment(fee) {
        this.$confirm({
          message: `Are you sure you want to make partial payment this item ?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              this.isBusy = true
              let data = {
                'payment_status': 'pending',
                'type': 'income',
                'title': 'Academic Fee',
                'invoice_id': fee.invoice_id,
                'student_fee_id': fee.id,
                'payer_user_id': fee.student_id,
                'total_amount': fee.remaining_amount,
                'is_full_paid': 1,
              };
              historyService.createQuote(fee.student_id, data).then((response) => {
                if(response.data.status == "OK") {
                  let history = response.data.history;
                  this.updatePaymentProcessed(fee, history);
                }
              }).catch(() => {
                  this.$snotify.error("Can't create payment")
              })
            }
          }
        });
      },

      updatePaymentProcessed(fee, history) {
        feeService.update(fee.student_id, fee.id,{is_payment_processed: 1}).then((res) => {
          if(res.data.status == "OK") {
            this.$tabs.open({name: "finance-history-type-update", params: {type: history.type, id: history.id}});
          }
          event.emit("refresh-student-fee");
        }).catch(() => {
          this.$snotify.error("Can't update payment processed")
        })
      },

      openInstallmentForm(fee) {
        this.dialog = true
        this.sub_fee = fee;
      },

      closeDialog() {
          this.dialog = false;
          this.sub_fee = null;
      },

      applyDiscount(feeId) {
        this.$confirm({
          message: `You wish to apply discount on this fee. Are you sure?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              feeService.applyDiscount(this.user.id, feeId).then(response => {
                if (!response.data.status)
                  this.$snotify.error(response.data.msg)
                else {
                  this.$snotify.success("Information updated")
                  this.getFees();
                }
              })
            }
          }
        });
      },

      cancelDiscount(feeId) {
        this.$confirm({
          message: `You wish to cancel discount on this fee. Are you sure?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              feeService.cancelDiscount(this.user.id, feeId).then(response => {
                if (!response.data.status)
                  this.$snotify.error(response.data.msg)
                else {
                  this.$snotify.success("Information updated")
                  this.getFees();
                }
              })
            }
          }
        });
      },
    }
};
</script>
